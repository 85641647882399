/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import clsx from 'clsx';
import { fetchBlockMapData } from '../../../../api';
import { useRegionFormatting, useStoreActions, useStoreState } from '../../../../hooks';
import { Card } from '../../../Card';
import { styles } from './styles';
import { CenteredSpinner } from '../../../CenteredSpinner';
import { calculateProjectData, getMapPin, parseGeometry } from '../../../../utils/utils';
import { MarketProjectExtended } from '../../../../store/types';
import { GOOGLE_MAPS_LIBRARIES } from '../../../../constants';
import { CustomPolygon } from '../../../map/CustomPolygon';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { LatLng } from '../../../../utils/types';
import { ProjectDetail } from '../../../map/ProjectDetail';
import { MapControls } from '../../../MapControls/MapControls';

export const Map: FC = () => {
  const { calculateVatPrice, getMinMaxProjectPrice } = useRegionFormatting();
  const setPolygons = useStoreActions((state) => state.market.setPolygons);
  const [mouseOverProjectId, setMouseOverProjectId] = useState<number>();
  const selectedProjectTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [center] = useState({ lat: 48.1323412, lng: 17.1119535 });
  const [data, setData] = useState<MarketProjectExtended[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [isNotFullscreen, setIsNotFullscreen] = useState(true);
  const mapRef = useRef<google.maps.Map | null>(null); // Reference to the map container
  const [isLoading, setLoading] = useState(true);
  const { profile } = useStoreState((store) => store.user);
  const dashboardName = useStoreState(
    (state) => state.market.dashboardOverviewData?.dashboard_name,
  );
  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );
  const selectedProject = useStoreState(
    (state) => state.market.selectedProject,
  );
  const [shownProject, setShownProject] = useState(selectedProject);
  const [open, setOpen] = useState(false);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const [currentPolygons, setCurrentPolygons] = useState<Record<string, LatLng[]>>({});

  const { t } = useTranslation();

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const dataWithoutNullGPS = useMemo(
    () =>
      data.filter(
        ({ gps_latitude, gps_longitude }) =>
          gps_latitude !== null && gps_longitude !== null,
      ),
    [data],
  );

  const hoveredProject = useMemo(
    () => dataWithoutNullGPS.find((p) => p.project_id === mouseOverProjectId),
    [mouseOverProjectId, dataWithoutNullGPS],
  );

  useEffect(() => {
    const handleFullScreenChange = (): void => {
      const { fullscreenElement } = document;
      setIsNotFullscreen(fullscreenElement === mapRef.current);
    };

    // Add event listeners for different browsers
    document.addEventListener('fullscreenchange', handleFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange, false);
    document.addEventListener('msfullscreenchange', handleFullScreenChange, false);

    // Cleanup
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, []);

  // this is just workaround to get rid of duplicated Bratislava region - should be handled on BE in the future
  // https://www.notion.so/builtmind/Region-Reload-string-21fad263b2a0419fabbf0c8a0289243d?pvs=4
  const removeDuplicatedRegion = (regions: string[]): string[] =>
    regions.filter(
      (region: string) => region.toLowerCase() !== 'bratislava region reload',
    );

  const convertToRegionNames = (citiesArray: string[]): string[] =>
    citiesArray.map((city) => city.split('_').map((_city) => _city.charAt(0).toUpperCase() + _city.slice(1)).join(' ').replace('Tmp', 'Region'));

  const onLoad = (mapParam: google.maps.Map): void => {
    if (dataWithoutNullGPS.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < dataWithoutNullGPS.length; i++) {
        bounds.extend(
          new window.google.maps.LatLng(
            dataWithoutNullGPS[i].gps_latitude,
            dataWithoutNullGPS[i].gps_longitude,
          ),
        );
      }
      mapParam.fitBounds(bounds);
    } else {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          mapParam.setCenter({ lat: latitude, lng: longitude });
        },
      );
    }
    mapRef.current = mapParam;
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedProject) {
      const calculatedProjectData = calculateProjectData(selectedProject, getMinMaxProjectPrice, calculateVatPrice);
      setShownProject(calculatedProjectData);
      setOpen(true);
    }
  }, [calculateVatPrice, selectedProject, profile]);

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockMapData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then(({ data: { cities: _cities, map: mapData, geometry } }) => {
          const convertedCities = removeDuplicatedRegion(convertToRegionNames(_cities));
          const mergedData = mapData.map((mapProject) => {
            const project = projects.find(
              ({ project_id }) => project_id === mapProject.project_id,
            );
            if (project) {
              return {
                ...project,
                ...mapProject,
              };
            }

            return {
              ...mapProject,
              available_units: 1,
              include: true,
            };
          });
          if (geometry) {
            const newGeometry = parseGeometry(geometry);
            setPolygons(JSON.parse(JSON.stringify(newGeometry)));
            setCurrentPolygons(parseGeometry(geometry));
          }
          setData(mergedData as MarketProjectExtended[]);
          setCities(convertedCities);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsPM, projects, pricePerSmCalculation]);

  return (
    <Card css={styles.card}>
      <div css={styles.mapContainer}>
        {isLoaded && !isLoading ? (
          <GoogleMap
            center={center}
            options={{
              disableDefaultUI: true,
              clickableIcons: false,
              restriction: {
                latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              },
            }}
            mapContainerClassName={clsx('map-container', 'block', {
              'map-info-window-hidden-exit-button': !!mouseOverProjectId,
            })}
            zoom={11}
            onLoad={onLoad}
            onClick={() => setSelectedProjectId(null)}
          >
            {isLoaded && mapRef.current && <MapControls mapParam={mapRef.current} />}
            {dataWithoutNullGPS
              .filter(
                ({ gps_latitude, gps_longitude }) =>
                  gps_latitude !== null && gps_longitude !== null,
              )
              .map(({ available_units, include, project_id, gps_latitude, gps_longitude }) => {
                const onMouseOver = (): void => setMouseOverProjectId(project_id);
                const onMouseOut = (): void => setMouseOverProjectId(undefined);
                const hoveredProjects = dataWithoutNullGPS.filter(
                  (p) =>
                    p.gps_latitude === gps_latitude &&
                    p.gps_longitude === gps_longitude,
                );
                const handleClick = (): void => {
                  onMouseOut();
                  clearTimeout(selectedProjectTimeout.current);
                  selectedProjectTimeout.current = setTimeout(
                    () => setSelectedProjectId(project_id),
                    0,
                  );
                };
                const filteredProjects = data.filter(
                  (p) =>
                    p.gps_latitude === gps_latitude &&
                    p.gps_longitude === gps_longitude,
                ).map((p) => calculateProjectData(p, getMinMaxProjectPrice, calculateVatPrice));
                return (
                  <Marker
                    key={project_id}
                    position={{ lat: gps_latitude, lng: gps_longitude }}
                    icon={getMapPin(available_units, false, include)}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={isNotFullscreen ? undefined : handleClick}
                  >
                    {(!isNotFullscreen && shownProject && selectedProject?.project_id === project_id) && open ? (
                      <InfoWindow
                        position={{
                          lat: selectedProject.gps_latitude,
                          lng: selectedProject.gps_longitude,
                        }}
                      >
                        <ProjectDetail
                          // @ts-ignore
                          selectedProject={data.find((d) => d.project_id === project_id)}
                          sameLocationProjects={filteredProjects}
                          onClose={handleClose}
                        />
                      </InfoWindow>
                    ) : (hoveredProject?.project_id === project_id && (
                      <InfoWindow
                        position={{
                          lat: hoveredProject.gps_latitude,
                          lng: hoveredProject.gps_longitude,
                        }}
                      >
                        <div className='map-info-window-hover-content'>
                          {hoveredProjects.map(({ project_name }) => (
                            <div key={project_name}>{project_name}</div>
                          ))}
                        </div>
                      </InfoWindow>
                      )
                    )}
                  </Marker>
                );
              })}
              {
                Object.values(currentPolygons).length > 0 &&
                Object.entries(currentPolygons).map(([name, polygon]) => (
                  <CustomPolygon
                    key={name}
                    paths={polygon}
                    name={name}
                  />
                ))
              }
          </GoogleMap>
        ) : (
          <CenteredSpinner />
        )}
      </div>
      <div css={styles.metaContainer}>
        <div css={styles.metaTitle}>{dashboardName}</div>
        <div>{cities?.join(', ')}</div>
        <div>
          {t('market.reports.map.projects_count', '{{count}} projects', {
            count: data.length,
          })}
        </div>
      </div>
    </Card>
  );
};
