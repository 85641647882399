import { CalendarIcon, ChartPieIcon, CheckCircleIcon, DocumentChartBarIcon, FlagIcon, RectangleStackIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TargetRow } from './TargetRow';
import { Target, Targets } from '../../../../api/mockData/sales';
import { useStoreActions } from '../../../../hooks';
import styles from './Targets.module.css';

type TargetsSectionProps = {
  onClose: () => void;
  targets: Targets;
}

export const TargetsSection: FC<TargetsSectionProps> = ({ onClose, targets }) => {
  const { t } = useTranslation();
  const { setIsEditingSales } = useStoreActions((actions) => actions.sales);
  const [targetsData, setTargetsData] = useState<Target[]>(targets.targets || []);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState<{ isEditing: boolean; index: number | null}>({ isEditing: false, index: null });
  const sortedTargets = targetsData.sort((a, b) => {
    if (a.mandatory === b.mandatory) {
      return dayjs(a.target_month).isBefore(dayjs(b.target_month)) ? -1 : 1;
    }
    return a.mandatory ? -1 : 1;
  });

  // const bigNumberFormatter = (num: number): string => {
  //   if (num > 1e6) {
  //     return `${(num / 1e6).toFixed(2)}m`;
  //   } if (num < 1e6 && num > 1e4) {
  //     return `${(num / 1e3).toFixed(2)}K`;
  //   }
  //     return `${num}`;
  // };

  useEffect(() => {
    if (targets.targets !== targetsData) {
      setTargetsData(targets.targets);
    }
  }, [targets, targetsData]);

  const addNew = (): void => {
    setIsCreating(true);
    setIsEditingSales(true);
  };

  const cancelTarget = (): void => {
    setIsCreating(false);
    setIsEditingSales(false);
  };

  const deleteTarget = (index: number): void => {
    setTargetsData(targetsData.filter((_, itemIndex) => itemIndex !== index));
  };

  const onEdit = (value: boolean, index: number): void => {
    if (isCreating) {
      message.info(t('sales.error.finish_creating', 'Please, finish creating target first.'));
      return;
    }
    if (value) {
      setIsEditing({ isEditing: true, index });
      setIsEditingSales(true);
    } else {
      setIsEditing({ isEditing: false, index: null });
      setIsEditingSales(false);
    }
  };

  return (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <div className={styles.sectionHeadersContent}>
        <div className={styles.badge}>
          <FlagIcon className={styles.icon} />
        </div>
        <span className='select-none'>{t('sales.targets.title', 'Sales Targets')}</span>
      </div>
      <XMarkIcon className={styles.close} onClick={onClose} />
    </div>
    <div className={styles.sectionRows}>
      <div className={styles.sectionColumns}>
        <div className={styles.sectionColumn}>
          <DocumentChartBarIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.type', 'Type')}</span>
        </div>
        <div className={styles.sectionColumn}>
          <FlagIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.target', 'Target')}</span>
        </div>
        <div className={styles.sectionColumn}>
          <RectangleStackIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.total', 'Total')}</span>
        </div>
        <div className={styles.sectionColumnCentered}>
          <CalendarIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.deadline', 'Deadline')}</span>
        </div>
        <div className={styles.sectionColumnCentered}>
          <CheckCircleIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.updates', 'Updates')}</span>
        </div>
        <div className={styles.sectionColumnCentered}>
          <ChartPieIcon className={styles.icon} />
          <span className={styles.fontBold}>{t('sales.targets.subtitle.progress', 'Progress')}</span>
        </div>
      </div>
      {sortedTargets.map((target, index) => (
        <TargetRow
          target={target}
          targets={targets}
          removeTarget={() => deleteTarget(index)}
          isEditing={isEditing.isEditing && isEditing.index === index}
          onEdit={() => onEdit(true, index)}
          onEditCancel={() => onEdit(false, index)}
        />
      ))}
      {isCreating && (
        <TargetRow
          targets={targets}
          removeTarget={() => cancelTarget()}
        />
      )}
      <div className={styles.sectionAddNew}>
        <span
          className={`text-bmgray cursor-pointer ${(isCreating || isEditing.isEditing) && 'opacity-25'}`}
          onClick={() => isCreating || isEditing.isEditing
            ? message.info(isCreating ? t('sales.error.finish_creating', 'Please, finish creating target first.') : t('sales.error.finish_editing', 'Please, finish editing target first.'))
            : addNew()}
        >
          + {t('sales.targets.add_new', 'Add New')}
        </span>
      </div>
    </div>
  </div>
);
};
