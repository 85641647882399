/** @jsxImportSource @emotion/react */
import { FC, MouseEvent } from 'react';
import { Popover, Checkbox, Button } from 'antd';
import { css } from '@emotion/react/macro';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { ExportToCsv, Options } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { useFlatLayout, useRegionFormatting, useStoreActions, useStoreState } from '../../../../hooks';
import { DemandDataUnitList } from '../../../../api/types';
import { round } from '../../../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_units`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type Props = {
  showValues?: boolean;
  onDownloadPNG?: () => void;
  exportData?: DemandDataUnitList[];
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
};

export const csvExporter = new ExportToCsv(options);

export const DemandCardPopover: FC<Props> = ({ showValues = false, onDownloadPNG, exportData }) => {
  const { displayValues } = useStoreState((state) => state.filters);
  const { setDisplayValues } = useStoreActions((action) => action.filters);
  const { t } = useTranslation();
  const { calculateVatPrice, getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayout } = useFlatLayout();

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    handlePropagation(e);
    setDisplayValues(!displayValues);
  };

  const handleButtonClick = async (exportFormat: string): Promise<void> => {
    if (exportData) {
      const updatedExportData = exportData.map(
        ({
          project_name,
          developer,
          address,
          id,
          building,
          category,
          orientation,
          DOM,
          current_availability,
          exterior_area,
          floor,
          floor_area,
          layout,
          price,
          price_per_sm,
          total_area,
          first_seen,
          parking_indoor_price,
          parking_outdoor_price,
          url,
          original_price,
          change,
          change_date,
          sale_type,
        }) => ({
          project: project_name,
          developer,
          address,
          id,
          building: building ?? '',
          category,
          current_availability: t(`enums.state.${current_availability.toLowerCase()}`),
          exterior_area: exterior_area ?? 0,
          floor: floor ?? '',
          floor_area: floor_area ?? '',
          layout: getLayout(layout.toLowerCase()),
          ...{
            ...withVAT ? {
              price_with_vat: price ? round(calculateVatPrice(price, floor_area, Number(exterior_area)) || 0, 2) : '',
              original_price_with_vat: original_price ? round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2) : '',
              price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
              price_per_sm_with_vat: price_per_sm ? round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2) : '',
              parking_indoor_price_with_vat: parking_indoor_price ? round(calculateVatPrice(parking_indoor_price, 1) || 0, 2) : '',
              parking_outdoor_price_with_vat: parking_outdoor_price ? round(calculateVatPrice(parking_outdoor_price, 1) || 0, 2) : '',
            } : {
              price_without_vat: price ? round(calculateVatPrice(price, floor_area, Number(exterior_area)) || 0, 2) : '',
              original_price_without_vat: original_price ? round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2) : '',
              price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
              price_per_sm_without_vat: price_per_sm ? round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2) : '',
              parking_indoor_price_without_vat: parking_indoor_price ? round(calculateVatPrice(parking_indoor_price, 1) || 0, 2) : '',
              parking_outdoor_price_without_vat: parking_outdoor_price ? round(calculateVatPrice(parking_outdoor_price, 1) || 0, 2) : '',
            },
          },
          total_area: total_area ?? '',
          orientation: orientation ?? '',
          first_seen,
          days_on_market: DOM,
          currency: getCurrency(),
          url: url ?? '',
          change,
          change_date,
          sale_type,
        }),
      );

      if (exportFormat === 'CSV') {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(updatedExportData);
      } else {
        const worksheet = XLSX.utils.json_to_sheet(updatedExportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
      }
    }
  };

  return (
    <Popover
      overlayClassName='report-actions'
      placement='bottom'
      content={
        <div css={styles.container}>
          {showValues && (
            <div className='actions' role='presentation' onClick={handleClick}>
              <div>
                {t('common.display_values')}
              </div>
              <Checkbox checked={displayValues} />
            </div>
          )}
          {onDownloadPNG && (
            <Button
              css={styles.download}
              icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
              onClick={onDownloadPNG}
              style={{ marginTop: showValues ? 10 : 0 }}
              type='text'
            >
              PNG
            </Button>
          )}
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('CSV')}
            type='text'
          >
            {t('market.button.export_csv')}
          </Button>
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('XLSX')}
            type='text'
          >
            {t('market.button.export_xlsx')}
          </Button>
        </div>
      }
    >
      <div
        role='button'
        tabIndex={0}
        className='actions-dots-left'
        onClick={handlePropagation}
      >
        <ArrowDownTrayIcon />
      </div>
    </Popover>
  );
};
