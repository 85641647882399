/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Spin, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { styles } from '../styles';
import { MarketShareChart } from './MarketShareChart';
import { ChartCard } from '../../../ChartCard';
import { useFilter } from '../useFilter';
import { Availability } from '../../../../store/types';
import { DateSelect } from './DateSelect';
import { getLastUpdateText, getReportExportFileName } from '../../../../utils/utils';
import { AvailableMarketShare, DisappearedMarketShare } from '../../../../api/secondaryClient';
import { statisticsApi } from '../../../../api/secondary';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

enum Filter {
  PROJECTS,
  DEVELOPERS,
  DISTRICTS,
}

export type MarketShareSaleSpeedChartData = {
  district_name: string;
  availability: Availability;
  date: string;
  count: number;
};

const mapData = (
  input: AvailableMarketShare[] | DisappearedMarketShare[],
  availability: Availability,
  ): MarketShareSaleSpeedChartData[] => {
  const result: MarketShareSaleSpeedChartData[] = [];
  input.forEach((data) => {
    if (data) {
      let marketShare;
      if (availability === Availability.AVAILABLE) {
        // @ts-expect-error checking other key.
        marketShare = data.availableMarketShare;
      } else {
        // @ts-expect-error checking other key.
        marketShare = data.disappearedMarketShare;
      }

      for (const district_name in marketShare) {
        if (marketShare[district_name]) {
          const chartData: MarketShareSaleSpeedChartData = {
            district_name,
            availability,
            date: data.month.slice(0, 7),
            count: marketShare[district_name],
          };

          result.push(chartData);
        }
      }
    }
  });

  return result;
};

export const MarketShare: FC = () => {
  const [saleSpeedData, setSaleSpeedData] = useState<
    MarketShareSaleSpeedChartData[]
  >([]);
  const [isLoading, setLoading] = useState(true);
  const { isRentals } = useMarketDetails();
  const { filterValue, handleChange } = useFilter(Availability.SOLD);
  const projectFilter = useFilter(Filter.DISTRICTS);
  const { paramsSM } = useStoreState((state) => state.filters);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { selectedDate } = useStoreState((state) => state.market);
  const { t } = useTranslation();
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      const startDate = date.toISOString().split('T')[0];
      const endDate = paramsSM.date;
      const payload = {
        reportId,
        startDate,
        endDate,
      };
      switch (filterValue) {
        case Availability.AVAILABLE:
          statisticsApi.getAvailableMarketShare(payload)
            .then((data) => {
              setSaleSpeedData([
                ...mapData(data, Availability.AVAILABLE),
              ]);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        default:
          statisticsApi.getDisappearedMarketShare(payload)
            .then((data) => {
              setSaleSpeedData([
                ...mapData(data, Availability.SOLD),
              ]);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }
    }
    setLoading(false);
  }, [paramsSM, filterValue, reportId]);

  const dates = useMemo(() => {
    if (!saleSpeedData || saleSpeedData.length === 0) return [];
    return [...new Set(saleSpeedData.map((d) => d.date))];
    }, [saleSpeedData],
  );

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.market_share.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={t('market.reports.market_share.title', 'Market Share')}
      subtitle={`${t(
        'market.reports.market_share.subtitle',
        'The number of available units in the selected projects.',
      )} ${getLastUpdateText(5, t)}`}
      extra={!isLoading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      controls={
        <div className='market-share'>
          <div className='segmented secondary'>
            <Segmented
              value={projectFilter.filterValue}
              options={[
                {
                  value: Filter.DISTRICTS,
                  label: t('enums.state.districts'),
                },
              ]}
              onChange={projectFilter.handleChange}
            />
            <Segmented
              style={{ margin: '0 1.2rem' }}
              value={filterValue}
              options={[
                {
                  value: Availability.SOLD,
                  label: isRentals ? t('enums.state.leased') : t('enums.state.sold'),
                },
                {
                  value: Availability.AVAILABLE,
                  label: t('enums.state.available'),
                },
              ]}
              onChange={handleChange}
            />
           {dates.length > 0 && (<DateSelect dates={dates} />)}
          </div>
        </div>
      }
      chart={
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          <MarketShareChart
            availability={filterValue as Availability}
            data={saleSpeedData.filter((d) => d.date === selectedDate)}
          />
        )
      }
    />
    </div>
  );
};
