/* eslint-disable react/no-danger */
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Row, Col } from 'antd';
import { FormatDailyNewsObjectOutput } from '../../utils/utils';
import { Icon } from './Icon';

const LIMIT = 90;

type Item = {
  variables: Record<string, string>[];
};

export type DailyNewsTransProps = {
  i18key: string;
  params: Record<string, string>[];
  onClick?: () => void;
  icon: {
    source: string;
    color: string;
  };
  t: TFunction;
  date: string;
  language: string;
  hoverable: boolean;
};

export const DailyNewsTrans: FC<DailyNewsTransProps> = ({
  i18key,
  language,
  params,
  onClick,
  hoverable,
  date,
  icon,
  t,
}) => {
  const combinedObject = params.reduce((result, currentObject) => {
    Object.keys(currentObject).forEach((key) => {
        result[key] = currentObject[key];
    });
    return result;
  }, {} as Record<string, string>);
  const trans = t(`daily_news.kpi.${i18key}`, { ...combinedObject });
  const len = trans.length;

  return (
    <>
      <Col span={2} className='daily_news_list_item_date' style={{ alignItems: len > LIMIT ? 'flex-start' : 'center', paddingTop: len > LIMIT ? '7.5px' : '0' }}>
        {new Date(date).toLocaleDateString(language, {
          month: 'short',
          day: '2-digit',
          year: '2-digit',
        })}
      </Col>
      <Col
        flex='auto'
        className='daily_news_list_item_container_value'
      >
        <div className={`daily_news_list_item_value ${len > LIMIT ? 'no-border' : 'border'} ${hoverable ? 'hoverable' : ''}`}>
          <div className='daily_news_list_item_trans'>
            { len < LIMIT ?
              <>
                <Icon color={icon.color} source={icon.source} />
                <Trans
                  t={t}
                  onClick={onClick}
                  i18nKey={`daily_news.kpi.${i18key}`}
                  values={{ ...combinedObject }}
                  shouldUnescape
                >
                  <strong>variable</strong>
                  <strong>variable</strong>
                  <strong>variable</strong>
                  <strong>variable</strong>
                </Trans>
              </> :
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onClick={() => onClick && onClick()}>
                <Row className='daily_news_list_item_value_border'>
                  <Icon color={icon.color} source={icon.source} />
                  <span
                    dangerouslySetInnerHTML={
                      {
                        __html: trans.substring(0, trans.indexOf('<2>')).replace(/<[0-9]>/g, '<strong>').replace(/<\/[0-9]>/g, '</strong>').concat('..'),
                      }
                    }
                  />
                </Row>
                <Row className='daily_news_list_item_value_border'>
                  <Icon color={icon.color} source={icon.source} />
                  <span
                    dangerouslySetInnerHTML={
                      {
                        __html: '..'.concat(trans.substring(trans.indexOf('<2>'), len).replace(/<[0-9]>/g, '<strong>').replace(/<\/[0-9]>/g, '</strong>')),
                      }
                    }
                  />
                </Row>
              </div>}
          </div>
        </div>
      </Col>
    </>
  );
};

export type FormatDailyNewsHelperProps = {
  date: string;
  language: string;
  icon: {
    source: string;
    color: string;
  };
  items: {
    i18key: string;
    project_id: number;
    onClick?: (item: Item) => void;
    variables: Record<string, string>[];
  }[];
}

export const formatDailyNewsObjectHelper = (
  arr: FormatDailyNewsHelperProps[],
  t: TFunction,
): FormatDailyNewsObjectOutput[] => {
  const result: FormatDailyNewsObjectOutput[] = [];
  arr.forEach((obj) => {
    obj.items.forEach((item) => {
      result.push({
        date: obj.date,
        project_id: item.project_id,
        value: () => (
          <DailyNewsTrans
            onClick={() => typeof item.onClick === 'function' ? item.onClick(item) : undefined}
            t={t}
            hoverable={typeof item.onClick === 'function'}
            date={obj.date}
            icon={obj.icon}
            i18key={item.i18key}
            language={obj.language}
            params={item.variables}
          />
          ),
      });
    });
  });

  return result;
};
