import { FC } from 'react';
import { message } from 'antd';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { getFlats, getFlatsFromS3 } from '../../api';
import { logError } from '../../utils/utils';
import { useReservedData } from '../../hooks/useReservedData';
import { Availability } from '../../store/types';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { round } from '../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_units`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

type FilterProps = {
    filter: string | number;
}

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const MarketExport: FC<FilterProps> = ({ filter }) => {
  const { calculateVatPrice, getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { mapReservedData } = useReservedData();

  const handleButtonClick = async (format: string): Promise<void> => {
    if (paramsPM) {
      try {
        message.loading(t('market.export.preparing_data'), 0);
        const { data } = await getFlats(paramsPM.dashboardId, paramsPM.date);
        const { is_prepared, s3url } = data;

        const { data: flats } = await getFlatsFromS3(s3url);

        const preparedFlats = mapReservedData(flats);
        if (is_prepared && (flats || []).length > 0) {
          const filterValue = filter === FilterEnum.AVAILABLE ? Availability.AVAILABLE : Availability.SOLD;
          const exportData = preparedFlats.filter((flat) => flat.availability === filterValue).map(
            ({
              project_name,
              developer,
              address,
              id,
              building,
              category,
              orientation,
              DOM,
              availability,
              exterior_area,
              floor,
              floor_area,
              layout,
              price,
              price_per_sm,
              total_area,
              first_seen,
              parking_indoor_price,
              parking_outdoor_price,
              url,
              original_price,
            }) => ({
              project: project_name,
              developer,
              address,
              id,
              building: building ?? '',
              category,
              availability: t(`enums.state.${availability.toLowerCase()}`),
              exterior_area: exterior_area ?? 0,
              floor: floor ?? '',
              floor_area: floor_area ?? '',
              layout: getLayout(layout),
              ...{
                ...withVAT ? {
                  price_with_vat: price ? round(calculateVatPrice(price, floor_area, Number(exterior_area)) || 0, 2) : '',
                  original_price_with_vat: original_price ? round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2) : '',
                  price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
                  price_per_sm_with_vat: price_per_sm ? round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2) : '',
                  parking_indoor_price_with_vat: parking_indoor_price ? round(calculateVatPrice(parking_indoor_price, 1) || 0, 2) : '',
                  parking_outdoor_price_with_vat: parking_outdoor_price ? round(calculateVatPrice(parking_outdoor_price, 1) || 0, 2) : '',
                } : {
                  price_without_vat: price ? round(calculateVatPrice(price, floor_area, Number(exterior_area)) || 0, 2) : '',
                  original_price_without_vat: original_price ? round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2) : '',
                  price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
                  price_per_sm_without_vat: price_per_sm ? round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2) : '',
                  parking_indoor_price_without_vat: parking_indoor_price ? round(calculateVatPrice(parking_indoor_price, 1) || 0, 2) : '',
                  parking_outdoor_price_without_vat: parking_outdoor_price ? round(calculateVatPrice(parking_outdoor_price, 1) || 0, 2) : '',
                },
              },
              total_area: total_area ?? '',
              orientation: orientation ?? '',
              first_seen,
              days_on_market: DOM,
              currency: getCurrency(),
              url: url ?? '',
          }));
          if (exportData.length === 0) {
            message.destroy();
            message.info(t('market.export.no_data'), 2);
            return;
          }
          if (format === 'csv') {
            csvExporter.options.filename = `${options.filename}${getVATText()}`;
            csvExporter.generateCsv(exportData);
          } else {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
          }
          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
        } else {
          logError('Data is not prepared for the export yet.');
        }
      } catch (error) {
        logError(error);
        message.error(t('error.generic'), 2);
      }
    }
  };

  return (
    <ExportDropdown type='marketData' onDownload={handleButtonClick} />
  );
};
