/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import clsx from 'clsx';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { Card } from '../../../Card';
import { styles } from './styles';
import { CenteredSpinner } from '../../../CenteredSpinner';
import { getMapPin, parseGeometry } from '../../../../utils/utils';
import { MarketProjectExtended } from '../../../../store/types';
import { GOOGLE_MAPS_LIBRARIES } from '../../../../constants';
import { getSecondaryMultiPolygon } from '../../../../api/secondary';
import { CustomPolygon } from '../../../map/CustomPolygon';
import { fetchBlockMapData } from '../../../../api';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { LatLng } from '../../../../utils/types';
import { MapControls } from '../../../MapControls/MapControls';

export const Map: FC = () => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { profile } = useStoreState((state) => state.user);
  const setPolygons = useStoreActions((state) => state.market.setPolygons);
  const [mouseOverProjectId, setMouseOverProjectId] = useState<number>();
  const [center, setCenter] = useState({ lat: 48.1323412, lng: 17.1119535 });
  const [data, _setData] = useState<MarketProjectExtended[]>([]);
  const [cities, _setCities] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();

  const [currentPolygons, setCurrentPolygons] = useState<Record<string, LatLng[]>>({});
  const [currentProjects, setCurrentProjects] = useState<MarketProjectExtended[]>([]);
  const projects = useStoreState((state) => state.market.getProjects);
  const actualProjects = useStoreState((state) => state.market.simplifiedProjects);

  const dashboardName = useMemo(() => {
    if (paramsSM) {
      return paramsSM.name;
    } if (paramsPM) {
      return paramsPM.name;
    }
    return '';
  }, [paramsPM, paramsSM]);

  useEffect(() => {
    projects.then(setCurrentProjects);
  }, [projects]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const dataWithoutNullGPS = useMemo(
    () =>
      data.filter(
        ({ gps_latitude, gps_longitude }) =>
          gps_latitude !== null && gps_longitude !== null,
      ),
    [data],
  );

  const hoveredProject = useMemo(
    () => dataWithoutNullGPS.find((p) => p.project_id === mouseOverProjectId),
    [mouseOverProjectId, dataWithoutNullGPS],
  );

  const onLoad = (mapParam: google.maps.Map): void => {
    if (dataWithoutNullGPS.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < dataWithoutNullGPS.length; i++) {
        bounds.extend(
          new window.google.maps.LatLng(
            dataWithoutNullGPS[i].gps_latitude,
            dataWithoutNullGPS[i].gps_longitude,
          ),
        );
      }
      mapParam.fitBounds(bounds);
    } else {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          mapParam.setCenter({ lat: latitude, lng: longitude });
        },
      );
    }
    mapRef.current = mapParam;
  };

  useEffect(() => {
    if (paramsSM) {
      getSecondaryMultiPolygon(paramsSM.reportId)
      .then((response) => {
        const parsedPolygons = parseGeometry(response);
        setCurrentPolygons(parsedPolygons);
        setPolygons(JSON.parse(JSON.stringify(parsedPolygons)));
      });
    }

    if (paramsPM) {
      fetchBlockMapData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
      .then(({ data: blockMapData }) => {
        const { cities: _cities, map: mapData } = blockMapData;
        const mergedData = mapData.map((mapProject) => {
          const project = actualProjects?.find(
            ({ project_id }) => project_id === mapProject.project_id,
          );
          if (project) {
            return {
              ...project,
              ...mapProject,
            };
          }

          return {
            ...mapProject,
            available_units: 1,
            include: true,
          };
        });
        _setData(mergedData as MarketProjectExtended[]);
        _setCities(_cities);
      })
      .finally(() => {
        setLoading(false);
      });
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjects, paramsSM, setPolygons, pricePerSmCalculation, paramsPM]);

  useEffect(() => {
    if (Object.values(currentPolygons).length > 0) {
      const polygon = Object.values(currentPolygons)[0][0];
      setCenter({
        lat: polygon.lat,
        lng: polygon.lng,
      });
    }
  }, [currentPolygons]);

  return (
    <Card css={styles.card}>
      <div css={styles.mapContainer}>
        {isLoaded && !isLoading ? (
          <GoogleMap
            center={center}
            options={{
              disableDefaultUI: true,
              clickableIcons: false,
              restriction: {
                latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              },
            }}
            mapContainerClassName={clsx('map-container', 'block', {
              'map-info-window-hidden-exit-button': !!mouseOverProjectId,
            })}
            zoom={11}
            onLoad={onLoad}
          >
            {isLoaded && mapRef.current && <MapControls mapParam={mapRef.current} />}
            {dataWithoutNullGPS
              .filter(
                ({ gps_latitude, gps_longitude }) =>
                  gps_latitude !== null && gps_longitude !== null,
              )
              .map(({ available_units, include, project_id, gps_latitude, gps_longitude }) => {
                const onMouseOver = (): void =>
                  setMouseOverProjectId(project_id);
                const onMouseOut = (): void => setMouseOverProjectId(undefined);
                const hoveredProjects = dataWithoutNullGPS.filter(
                  (p) =>
                    p.gps_latitude === gps_latitude &&
                    p.gps_longitude === gps_longitude,
                );
                return (
                  <Marker
                    key={project_id}
                    position={{ lat: gps_latitude, lng: gps_longitude }}
                    icon={getMapPin(available_units, false, include)}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                  >
                    {hoveredProject?.project_id === project_id && (
                      <InfoWindow
                        position={{
                          lat: hoveredProject.gps_latitude,
                          lng: hoveredProject.gps_longitude,
                        }}
                      >
                        <div css={styles.hoverContent}>
                          {hoveredProjects.map(({ project_name }) => (
                            <div key={project_name}>{project_name}</div>
                          ))}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
              {
                Object.values(currentPolygons).length > 0 &&
                Object.entries(currentPolygons).map(([name, polygon], _) => (
                  <CustomPolygon
                    key={name}
                    paths={polygon}
                    name={name}
                  />
                ))
              }
          </GoogleMap>
        ) : (
          <CenteredSpinner />
        )}
      </div>
      <div css={styles.metaContainer}>
        <div css={styles.metaTitle}>{dashboardName}</div>
        <div>{cities?.join(', ')}</div>
        <div>
          {t('market.reports.map.projects_count', '{{count}} projects', {
            count: data.length,
          })}
        </div>
      </div>
    </Card>
  );
};
