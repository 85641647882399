import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Card, InfoPopover } from '../../components';
import styles from './ComparisonBreakdown.module.css';
import { useStoreState } from '../../hooks';

type BreakdownRowProps = {
  housePartKey: string;
};

export const ComparisonBreakdown: FC = () => {
  const { t } = useTranslation();
  const { data } = useStoreState((state) => state.priceListDetail);
  const isHouse = !!data?.price_breakdown.floor.floor_count?.value;

  const BreakdownRow: FC<BreakdownRowProps> = ({ housePartKey }) => (
    <div className='flex items-center justify-between h-10'>
      <div className='leading-5'>
        {t(`enums.house_parts.${housePartKey}`, housePartKey)}
      </div>
      <InfoPopover
        popoverProps={{
          content: (
            <div>
              <p>{t(`price_list.hints.${housePartKey}.desc`, housePartKey)}</p>
            </div>
          ),
        }}
      />
    </div>
  );

  return (
    <Card title={t('price_list.detail.price_breakdown.title')}>
      <div className='space-y-10 sm:w-[37rem]'>
        <div className={styles.imageSpace} />
        <div className='grid auto-rows-auto gap-10'>
          {/* INTERIOR */}
          <div className='grid grid-cols-2'>
            <div className='font-semibold row-span-5'>
              {t('enums.house_areas.interior')}
            </div>
            <BreakdownRow housePartKey='layout' />
            <BreakdownRow housePartKey='layout_class' />
            <BreakdownRow housePartKey='floor_area' />
            {!isHouse ? (
              <BreakdownRow housePartKey='difference_floor_area' />
            ) : null}
          </div>
          {/* EXTERIOR */}
          <div className='grid grid-cols-2'>
            <div
              className={clsx(
                'font-semibold',
                isHouse ? 'row-span-9' : 'row-span-4',
              )}
            >
              {t('enums.house_areas.exterior')}
            </div>
            <BreakdownRow housePartKey='has_no_exterior' />
            {isHouse ? (
              <>
                <BreakdownRow housePartKey='lot_area' />
                <BreakdownRow housePartKey='garage_count' />
              </>
            ) : (
              <>
                <BreakdownRow housePartKey='balcony_area' />
                <BreakdownRow housePartKey='terrace_area' />
                <BreakdownRow housePartKey='garden_area' />
              </>
            )}
          </div>
          {/* FLOOR */}
          <div className='grid grid-cols-2'>
            <div
              className={clsx(
                'font-semibold',
                isHouse ? 'row-span-1' : 'row-span-3',
              )}
            >
              {t('enums.house_areas.floor')}
            </div>
            {isHouse ? (
              <BreakdownRow housePartKey='floor_count' />
            ) : (
              <>
                <BreakdownRow housePartKey='floor' />
                <BreakdownRow housePartKey='top_floor' />
                <BreakdownRow housePartKey='bottom_floor' />
              </>
            )}
          </div>
          {/* ORIENTATION */}
          <div className='grid grid-cols-2'>
            <div className='font-semibold row-span-4'>
              {t('enums.house_areas.orientation')}
            </div>
            <BreakdownRow housePartKey='cardinal_direction' />
            <BreakdownRow housePartKey='view' />
            <BreakdownRow housePartKey='distance' />
            {!isHouse ? <BreakdownRow housePartKey='num_sides' /> : null}
          </div>
          {/* LAYOUT */}
          <div className='grid grid-cols-2'>
            <div
              className={clsx(
                'font-semibold max-w-[5rem]',
                isHouse ? 'row-span-7' : 'row-span-9',
              )}
            >
              {t('enums.house_areas.layout')}
            </div>
            <BreakdownRow housePartKey='num_of_bathrooms' />
            <BreakdownRow housePartKey='walk_through_room' />
            <BreakdownRow housePartKey='kitchen_luminosity' />
            {!isHouse ? <BreakdownRow housePartKey='next_to_elevator' /> : null}
            <BreakdownRow housePartKey='ratio_bedrooms' />
            <BreakdownRow housePartKey='ratio_living' />
            <BreakdownRow housePartKey='noise' />
            {!isHouse ? (
              <>
                <BreakdownRow housePartKey='permit_regular' />
                <BreakdownRow housePartKey='category' />
              </>
            ) : (
              <>
                <BreakdownRow housePartKey='house_type' />
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
