import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { Profile } from '../api/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const transformPriceListBreakdown = (
  dataArray: PriceListDetailData[],
  calculateVatPrice: (
    value: number | null,
    floor_area: number | null,
    exterior_area?: number | null,
  ) => number | null,
  profile?: Profile,
) =>
  dataArray.map((data) => {
    const { floor_area, exterior_area } = data.description;
    const { current_price, new_price } = data.price;
    const { interior, exterior, floor, orientation, layout, analytics } =
      data.price_breakdown;

    const isHouse = !!floor.floor_count;

    return {
      ...data,
      price: {
        current_price: {
          from: current_price.from,
          price:
            calculateVatPrice(current_price.price, floor_area, exterior_area) ??
            0,
          price_per_sm:
            calculateVatPrice(
              current_price.price_per_sm,
              floor_area,
              exterior_area,
            ) ?? 0,
        },
        new_price: {
          difference: new_price.difference,
          price:
            calculateVatPrice(new_price.price, floor_area, exterior_area) ?? 0,
          price_per_sm:
            calculateVatPrice(
              new_price.price_per_sm,
              floor_area,
              exterior_area,
            ) ?? 0,
        },
        currency: data.price.currency,
      },
      price_breakdown: {
        ...data.price_breakdown,
        interior: {
          ...interior,
          difference_floor_area: {
            ...interior.difference_floor_area,
            price:
              calculateVatPrice(
                interior?.difference_floor_area?.price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                interior?.difference_floor_area?.price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                interior?.difference_floor_area?.update_price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                interior?.difference_floor_area?.update_price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          floor_area: {
            ...interior.floor_area,
            price:
              calculateVatPrice(
                interior?.floor_area.price ?? null,
                floor_area,
                exterior_area ?? 0,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                interior?.floor_area?.price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                interior?.floor_area?.update_price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                interior?.floor_area?.update_price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          layout: {
            ...interior.layout,
            price:
              calculateVatPrice(
                interior?.layout?.price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                interior?.layout?.price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                interior?.layout?.update_price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                interior?.layout?.update_price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          layout_class: {
            ...interior.layout_class,
            price:
              calculateVatPrice(
                interior?.layout_class?.price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                interior?.layout_class?.price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                interior?.layout_class?.update_price ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                interior?.layout_class?.update_price_psm ?? null,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
        },
        exterior: {
          ...exterior,
          ...(isHouse
            ? {}
            : {
                balcony_area: {
                  ...exterior.balcony_area,
                  price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.balcony_area?.price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.balcony_area.price,
                  price_psm: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.balcony_area.price_psm,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.balcony_area.price_psm,
                  update_price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.balcony_area.update_price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.balcony_area.update_price,
                  update_price_psm: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.balcony_area.update_price_psm,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.balcony_area.update_price_psm,
                },
                garden_area: {
                  ...exterior.garden_area,
                  price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.garden_area.price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.garden_area.price,
                  price_psm: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.garden_area.price_psm,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.garden_area.price_psm,
                  update_price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.garden_area.update_price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.garden_area.update_price,
                  update_price_psm: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.garden_area.update_price_psm,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.garden_area.update_price_psm,
                },
                terrace_area: {
                  ...exterior.terrace_area,
                  price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.terrace_area.price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.terrace_area.price,
                  price_psm: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.terrace_area.price_psm,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.terrace_area.price_psm,
                  update_price: profile?.price_per_sm_calculation
                    ? calculateVatPrice(
                        exterior.terrace_area.update_price,
                        floor_area,
                        exterior_area,
                      ) ?? 0
                    : exterior.terrace_area.update_price,
                },
              }),
          has_no_exterior: exterior.has_no_exterior && {
            ...exterior.has_no_exterior,
            price: profile?.price_per_sm_calculation
              ? calculateVatPrice(
                  exterior.has_no_exterior.price,
                  floor_area,
                  exterior_area,
                ) ?? 0
              : exterior.has_no_exterior.price,
            price_psm: profile?.price_per_sm_calculation
              ? calculateVatPrice(
                  exterior.has_no_exterior.price_psm,
                  floor_area,
                  exterior_area,
                ) ?? 0
              : exterior.has_no_exterior.price_psm,
            update_price: profile?.price_per_sm_calculation
              ? calculateVatPrice(
                  exterior.has_no_exterior.update_price,
                  floor_area,
                  exterior_area,
                ) ?? 0
              : exterior.has_no_exterior.update_price,
            update_price_psm: profile?.price_per_sm_calculation
              ? calculateVatPrice(
                  exterior.has_no_exterior.update_price_psm,
                  floor_area,
                  exterior_area,
                ) ?? 0
              : exterior.has_no_exterior.update_price_psm,
          },
        },
        floor: isHouse
          ? {
              ...floor,
              floor_count: {
                ...floor.floor_count,
                price:
                  calculateVatPrice(
                    floor.floor_count.price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                price_psm:
                  calculateVatPrice(
                    floor.floor_count.price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price:
                  calculateVatPrice(
                    floor.floor_count.update_price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price_psm:
                  calculateVatPrice(
                    floor.floor_count.update_price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
              },
            }
          : {
              ...floor,
              bottom_floor: {
                ...floor.bottom_floor,
                price:
                  calculateVatPrice(
                    floor.bottom_floor.price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                price_psm:
                  calculateVatPrice(
                    floor.bottom_floor.price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price:
                  calculateVatPrice(
                    floor.bottom_floor.update_price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price_psm:
                  calculateVatPrice(
                    floor.bottom_floor.update_price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
              },
              floor: {
                ...floor.floor,
                price:
                  calculateVatPrice(
                    floor.floor.price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                price_psm:
                  calculateVatPrice(
                    floor.floor.price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price:
                  calculateVatPrice(
                    floor.floor.update_price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
              },
              top_floor: {
                ...floor.top_floor,
                price:
                  calculateVatPrice(
                    floor.top_floor.price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                price_psm:
                  calculateVatPrice(
                    floor.top_floor.price_psm,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
                update_price:
                  calculateVatPrice(
                    floor.top_floor.update_price,
                    floor_area,
                    exterior_area,
                  ) ?? 0,
              },
            },
        orientation: {
          ...orientation,
          cardinal_direction: {
            ...orientation.cardinal_direction,
            price:
              calculateVatPrice(
                orientation.cardinal_direction.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                orientation.cardinal_direction.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                orientation.cardinal_direction.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                orientation.cardinal_direction.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          distance: {
            ...orientation.distance,
            price:
              calculateVatPrice(
                orientation.distance.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                orientation.distance.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                orientation.distance.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                orientation.distance.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          ...(isHouse
            ? {}
            : {
                num_sides: {
                  ...orientation.num_sides,
                  price:
                    calculateVatPrice(
                      orientation.num_sides.price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  price_psm:
                    calculateVatPrice(
                      orientation.num_sides.price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price:
                    calculateVatPrice(
                      orientation.num_sides.update_price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price_psm:
                    calculateVatPrice(
                      orientation.num_sides.update_price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                },
              }),
          view: {
            ...orientation.view,
            price:
              calculateVatPrice(
                orientation.view.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                orientation.view.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                orientation.view.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                orientation.view.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
        },
        layout: {
          ...layout,
          ...(isHouse
            ? {}
            : {
                category: {
                  ...layout.category,
                  price:
                    calculateVatPrice(
                      layout.category.price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  price_psm:
                    calculateVatPrice(
                      layout.category.price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price:
                    calculateVatPrice(
                      layout.category.update_price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price_psm:
                    calculateVatPrice(
                      layout.category.update_price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                },
              }),
          ...(isHouse
            ? {
                house_type: {
                  ...layout.house_type,
                  price:
                    calculateVatPrice(
                      layout.house_type.price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  price_psm:
                    calculateVatPrice(
                      layout.house_type.price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price:
                    calculateVatPrice(
                      layout.house_type.update_price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price_psm:
                    calculateVatPrice(
                      layout.house_type.update_price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                },
              }
            : {}),
          kitchen_luminosity: {
            ...layout.kitchen_luminosity,
            price:
              calculateVatPrice(
                layout.kitchen_luminosity.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout.kitchen_luminosity.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout.kitchen_luminosity.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout.kitchen_luminosity.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          ...(isHouse
            ? {}
            : {
                next_to_elevator: {
                  ...layout.next_to_elevator,
                  price:
                    calculateVatPrice(
                      layout.next_to_elevator.price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  price_psm:
                    calculateVatPrice(
                      layout.next_to_elevator.price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price:
                    calculateVatPrice(
                      layout.next_to_elevator.update_price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price_psm:
                    calculateVatPrice(
                      layout.next_to_elevator.update_price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                },
              }),
          noise: {
            ...layout.noise,
            price:
              calculateVatPrice(
                layout.noise.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout.noise.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout.noise.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout.noise.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          num_of_bathrooms: {
            ...layout.num_of_bathrooms,
            price:
              calculateVatPrice(
                layout.num_of_bathrooms.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout.num_of_bathrooms.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout.num_of_bathrooms.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout.num_of_bathrooms.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          ...(isHouse
            ? {}
            : {
                permit_regular: {
                  ...layout.permit_regular,
                  price:
                    calculateVatPrice(
                      layout.permit_regular.price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  price_psm:
                    calculateVatPrice(
                      layout.permit_regular.price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price:
                    calculateVatPrice(
                      layout.permit_regular.update_price,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                  update_price_psm:
                    calculateVatPrice(
                      layout.permit_regular.update_price_psm,
                      floor_area,
                      exterior_area,
                    ) ?? 0,
                },
              }),
          ratio_bedrooms: {
            ...layout.ratio_bedrooms,
            price:
              calculateVatPrice(
                layout.ratio_bedrooms.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout.ratio_bedrooms.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout.ratio_bedrooms.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout.ratio_bedrooms.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          ratio_living: {
            ...layout.ratio_living,
            price:
              calculateVatPrice(
                layout?.ratio_living?.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout?.ratio_living?.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout?.ratio_living?.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout?.ratio_living?.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
          walk_through_room: {
            ...layout.walk_through_room,
            price:
              calculateVatPrice(
                layout.walk_through_room.price,
                floor_area,
                exterior_area,
              ) ?? 0,
            price_psm:
              calculateVatPrice(
                layout.walk_through_room.price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price:
              calculateVatPrice(
                layout.walk_through_room.update_price,
                floor_area,
                exterior_area,
              ) ?? 0,
            update_price_psm:
              calculateVatPrice(
                layout.walk_through_room.update_price_psm,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
        },
        analytics: {
          ...analytics,
          google: {
            ...analytics.google,
            price:
              calculateVatPrice(
                analytics.google.price,
                floor_area,
                exterior_area,
              ) ?? 0,
          },
        },
      },
    };
  });
