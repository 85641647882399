import { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { CheckIcon } from '@heroicons/react/24/outline';
import { formatDifference, getNumericSorter } from '../../utils';
import { Formatters } from '../../hooks/useRegionFormatting';
import { PriceListTableData } from '../../api/mockData/priceListData';
import { sortByString } from '../../utils/utils';
import { AvailabilityBadge } from '../AvailabilityBadge/AvailablityBadge';

const smallCol = '10rem';
const mediumCol = '12rem';
const largeCol = '15rem';

export const getColumns = (
  t: TFunction,
  formatCurrency: Formatters['formatCurrency'],
  formatAreaUnits: Formatters['formatAreaUnits'],
  layouts: string[],
  availabilities: string[],
  getLayout: (value: string) => string,
  getLayoutAbbr: (value: string) => string,
  showPricePerSM: boolean,
  sortLayouts: Formatters['sortLayouts'],
  isRent: boolean,
  isHouse: boolean,
  isCompare?: boolean,
  selectedUnits?: number[],
): ColumnType<PriceListTableData>[] => [
  {
    dataIndex: 'internal_id',
    title: t('price_list.table_column_headers.id'),
    render: (value, { id }) =>
      isCompare ? (
        <span className='flex items-center justify-end space-x-2'>
          {selectedUnits && selectedUnits.includes(id) && (
            <CheckIcon className='w-7 h-7 stroke-[3px]' />
          )}
          <b>{value}</b>
        </span>
      ) : (
        <Link to={`${id}`}>
          <b>{value}</b>
        </Link>
      ),
    sorter: (a, b) => a.internal_id.localeCompare(b.internal_id),
    width: smallCol,
  },
  {
    dataIndex: 'layout',
    title: () => (
      <Tooltip title={t('price_list.table_column_headers.layout')}>
        {t('price_list.table_column_headers.layout')}
      </Tooltip>
    ),
    render: (value) => <div>{getLayoutAbbr(value)}</div>,
    ellipsis: { showTitle: true },
    sorter: ({ layout: layout_a }, { layout: layout_b }) =>
      sortLayouts(layout_a, layout_b),
    filters: layouts.map((value) => ({
      text: getLayout(value),
      value,
    })),
    onFilter: (value, record) => record.layout === value,
    width: largeCol,
  },
  {
    dataIndex: isHouse ? 'floor_count' : 'floor',
    title: () => (
      <Tooltip
        title={t(
          `price_list.table_column_headers.${
            isHouse ? 'floor_count' : 'floor'
          }`,
        )}
      >
        {t(
          `price_list.table_column_headers.${
            isHouse ? 'floor_count' : 'floor'
          }`,
        )}
      </Tooltip>
    ),
    ellipsis: { showTitle: true },
    sorter: getNumericSorter(isHouse ? 'floor_count' : 'floor'),
    width: mediumCol,
  },
  {
    dataIndex: 'floor_area',
    title: () => (
      <Tooltip title={t('price_list.table_column_headers.floor_area')}>
        {t('price_list.table_column_headers.floor_area')}
      </Tooltip>
    ),
    render: (value) => formatAreaUnits(value),
    ellipsis: { showTitle: true },
    sorter: getNumericSorter('floor_area'),
    width: mediumCol,
  },
  {
    dataIndex: 'exterior_area',
    title: () => (
      <Tooltip title={t('price_list.table_column_headers.exterior_area')}>
        {t('price_list.table_column_headers.exterior_area')}
      </Tooltip>
    ),
    render: (value) => formatAreaUnits(value),
    ellipsis: { showTitle: true },
    sorter: getNumericSorter('exterior_area'),
    width: mediumCol,
  },
  {
    dataIndex: 'availability',
    title: () => (
      <Tooltip title={t('price_list.table_column_headers.availability')}>
        {t('price_list.table_column_headers.availability')}
      </Tooltip>
    ),
    render: (value) => (
      <AvailabilityBadge unitAvailability={value} type='table' />
    ),
    ellipsis: true,
    sorter: (
      { availability: availability_a },
      { availability: availability_b },
    ) =>
      sortByString(
        t(`enums.state.${availability_a}`),
        t(`enums.state.${availability_b}`),
      ),
    filters: availabilities.map((value) => ({
      text: t(`enums.state.${value}`),
      value,
    })),
    onFilter: (value, record) => record.availability === value,
    width: largeCol,
  },
  {
    dataIndex: 'current_price',
    title: () => (
      <Tooltip
        title={t(
          `price_list.table_column_headers.current_${
            isRent ? 'rent' : 'price'
          }`,
        )}
      >
        {t(
          `price_list.table_column_headers.current_${
            isRent ? 'rent' : 'price'
          }`,
        )}
      </Tooltip>
    ),
    render: (value, { current_price_per_sm }) =>
      formatCurrency(showPricePerSM ? current_price_per_sm : value),
    sorter: getNumericSorter(
      showPricePerSM ? 'current_price_per_sm' : 'current_price',
    ),
    ellipsis: true,
  },
  {
    dataIndex: 'new_price',
    title: () => (
      <Tooltip
        title={t(
          `price_list.table_column_tooltips.recommended_${
            isRent ? 'rent' : 'price'
          }`,
          'Recommended Price',
        )}
      >
        {t(
          `price_list.table_column_headers.recommended_${
            isRent ? 'rent' : 'price'
          }`,
          'Recommended Price',
        )}
      </Tooltip>
    ),
    render: (value, { new_price_per_sm }) => (
      <span style={{ fontWeight: 600 }}>
        {formatCurrency(showPricePerSM ? new_price_per_sm : value)}
      </span>
    ),
    ellipsis: true,
    sorter: getNumericSorter(showPricePerSM ? 'new_price_per_sm' : 'new_price'),
  },
  {
    dataIndex: 'price_difference',
    title: () => (
      <Tooltip title={t('price_list.table_column_headers.update', 'Update')}>
        {t('price_list.table_column_headers.update', 'Update')}
      </Tooltip>
    ),
    render: (value) => formatDifference(value ?? 0),
    sorter: getNumericSorter('price_difference'),
    ellipsis: true,
    width: largeCol,
  },
  {
    dataIndex: 'columnFilter',
    title: () => <span />,
    width: '5rem',
  },
];
