/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Action,
  action,
  computed,
  Computed,
  thunk,
  Thunk,
  unstable_effectOn,
  Unstable_EffectOn,
} from 'easy-peasy';
import localforage from 'localforage';
import {
  createMarketDashboard,
  deleteMarketDashboard,
  fetchMarketDashboardOverview,
  updateMarketDashboard,
  fetchUserMDTemplates,
  fetchMarketProjects,
  fetchMarketDashboardSimplified,
} from '../api';
import {
  Availability,
  MarketProjectExtended,
  Mode,
  StoreModel,
  TableData,
} from './types';
import {
  CreateMarketDashboardParameters,
  MarketDashboardOverview,
  MarketDashboardTemplate,
  SecondaryDashboardParameters,
} from '../api/types';
import { MarketDashboardData } from '../api/mockData/marketDashboardData';
import { getGeometry, logError, parseGeometry } from '../utils/utils';
import {
  createSecondaryReport,
  getSecondaryMultiPolygon,
  reportsApi,
} from '../api/secondary';
// import { MarketTypeEnum, PricePerSMCalcEnum } from '../api/enums';
import { MarketTypeEnum } from '../api/enums';
import { MarketDashboard } from '../api/mockData/overviewData';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { LatLng } from '../utils/types';

export interface SimplifiedProjects {
  gps_latitude: number;
  gps_longitude: number;
  include: boolean | undefined;
  project_id: number;
  available_units: number;
  reserved_units: number;
  sold_units: number;
  avg_price: number | null; // check if I could get rid of those 4 avg prices att
  avg_price_per_sm: number | null;
  sold_avg_price: number | null;
  sold_avg_price_per_sm: number | null;
  total_number_of_units: number | null;
  project_name: string;
  developer: string;
  amenities: number;
  schools: number;
  district: string;
  city: string;
  municipality: string;
  date_construction_completion: string | null;
  layouts: string[];
  baths: number[];
  garages: number[];
  unit_type: string;
  using_total_area?: boolean;
}
export interface MarketStore {
  templates: MarketDashboardTemplate[];
  dashboardOverviewData: MarketDashboardOverview | null;
  data: MarketDashboardData | null;
  projects: MarketProjectExtended[];
  simplifiedProjects: SimplifiedProjects[];
  setSimplifiedProjects: Action<MarketStore, SimplifiedProjects[]>;
  getProjects: Computed<
    MarketStore,
    Promise<MarketProjectExtended[]>,
    StoreModel
  >;
  reportsOrder: string[];
  projectsLoading: boolean;
  selectedProjectId: number | null;
  polygons: Record<string, LatLng[]>;
  primaryParameters: CreateMarketDashboardParameters | null;
  secondaryParameters: SecondaryDashboardParameters | null;
  selectedProject: Computed<
    MarketStore,
    MarketProjectExtended | undefined,
    StoreModel
  >;
  mode: Mode;
  addReportStep: number | null;
  selectedCompetitors: number[];
  selectedProjectNames: string[];
  selectedDeveloperNames: string[];
  selectedDate?: string;
  selectedDates?: string[];
  showPricePerSM: boolean;
  isMaximized: boolean;
  tableData: TableData<SimplifiedProjects>;
  onDashboardIdChange: Unstable_EffectOn<MarketStore, StoreModel>;
  setTemplates: Action<MarketStore, MarketDashboardTemplate[]>;
  setSelectedCompetitors: Action<MarketStore, number[]>;
  setSelectedProjectNames: Action<MarketStore, string[]>;
  setSelectedDeveloperNames: Action<MarketStore, string[]>;
  setSelectedDate: Action<MarketStore, string>;
  setSelectedDates: Action<MarketStore, string[]>;
  setShowPricePerSM: Action<MarketStore, boolean>;
  setDashboardOverviewData: Action<MarketStore, MarketDashboardOverview | null>;
  setProjects: Action<MarketStore, MarketProjectExtended[]>;
  setReportsOrder: Action<MarketStore, string[]>;
  setProjectsLoading: Action<MarketStore, boolean>;
  setPolygons: Action<MarketStore, Record<string, LatLng[]>>;
  resetPolygons: Action<MarketStore>;
  setPrimaryParameters: Action<
    MarketStore,
    CreateMarketDashboardParameters | null
  >;
  setSecondaryParameters: Action<
    MarketStore,
    SecondaryDashboardParameters | null
  >;
  setSelectedProjectId: Action<MarketStore, number | null>;
  setMode: Action<MarketStore, Mode>;
  setAddReportStep: Action<MarketStore, number | null>;
  setTableData: Action<MarketStore, TableData<SimplifiedProjects>>;
  setIsMaximized: Action<MarketStore, boolean>;
  resetTableData: Action<MarketStore>;
  handleInclude: Action<MarketStore, React.Key[]>;
  handleIncludeAll: Action<MarketStore, React.Key[]>;
  fetchAllProjects: Thunk<MarketStore, undefined, unknown, StoreModel>;
  createPrimaryDashboard: Thunk<MarketStore, undefined, unknown, StoreModel>;
  createSecondaryDashboard: Thunk<MarketStore, undefined, unknown, StoreModel>;
  fetchDashboardSettings: Thunk<
    MarketStore,
    { dashboardId: number; date: string } | null,
    unknown,
    StoreModel
  >;
  fetchDashboardOverview: Thunk<
    MarketStore,
    { dashboardId: number; date: string } | null,
    unknown,
    StoreModel
  >;
  deleteDashboard: Thunk<
    MarketStore,
    { primaryId?: number; secondaryId?: string; rentId?: string },
    unknown,
    StoreModel
  >;
  updateDashboard: Thunk<MarketStore, undefined, unknown, StoreModel>;
  fetchUserTemplates: Thunk<MarketStore, undefined, unknown, StoreModel>;
}

export const defaultTableData = {
  filteredData: [],
  filters: {},
  pagination: {},
  sorter: {},
};

export const marketStore: MarketStore = {
  isMaximized: false,
  templates: [],
  data: null,
  dashboardOverviewData: null,
  projects: [],
  simplifiedProjects: [],
  reportsOrder: [],
  polygons: {},
  projectsLoading: false,
  selectedProjectId: null,
  primaryParameters: null,
  secondaryParameters: null,
  selectedCompetitors: [],
  selectedProjectNames: [],
  selectedDeveloperNames: [],
  addReportStep: null,
  mode: Mode.READ,
  tableData: defaultTableData,
  selectedProject: computed(
    [(state) => state.selectedProjectId, (state) => state.projects],
    (selectedProjectId, projects) =>
      projects.find((p) => p.project_id === selectedProjectId),
  ),
  getProjects: computed([(state) => state.projects], async (projects) => {
    if (!projects || projects.length === 0) {
      try {
        const value = await localforage.getItem('projects');
        return value as MarketProjectExtended[];
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    return projects;
  }),
  showPricePerSM: false,
  onDashboardIdChange: unstable_effectOn(
    [(state, globalState) => globalState.filters.paramsPM?.dashboardId],
    (actions, { prev }, helpers) => {
      const {
        market: { resetTableData, setSelectedCompetitors },
      } = helpers.getStoreActions();
      if (prev[0]) {
        setSelectedCompetitors([]);
        resetTableData();
      }
    },
  ),
  setTemplates: action((state, value) => {
    state.templates = value;
  }),
  setIsMaximized: action((state, value) => {
    state.isMaximized = value;
  }),
  setShowPricePerSM: action((state, value) => {
    state.showPricePerSM = value;
  }),
  setDashboardOverviewData: action((state, value) => {
    state.dashboardOverviewData = value;
  }),
  setSimplifiedProjects: action((state, value) => {
    state.simplifiedProjects = value;
  }),
  setProjects: action((state, value) => {
    state.projects = value;
  }),
  setReportsOrder: action((state, value) => {
    state.reportsOrder = value;
  }),
  setSelectedProjectId: action((state, value) => {
    state.selectedProjectId = value;
  }),
  setPrimaryParameters: action((state, value) => {
    state.primaryParameters = value;
  }),
  setSecondaryParameters: action((state, value) => {
    state.secondaryParameters = value;
  }),
  setPolygons: action((state, value) => {
    state.polygons = value;
  }),
  resetPolygons: action((state) => {
    state.polygons = {};
  }),
  setProjectsLoading: action((state, value) => {
    state.projectsLoading = value;
  }),
  setMode: action((state, value) => {
    state.mode = value;
  }),
  setAddReportStep: action((state, value) => {
    state.addReportStep = value;
  }),
  setSelectedCompetitors: action((state, value) => {
    state.selectedCompetitors = value;
  }),
  setSelectedProjectNames: action((state, value) => {
    state.selectedProjectNames = value;
  }),
  setSelectedDeveloperNames: action((state, value) => {
    state.selectedDeveloperNames = value;
  }),
  setSelectedDate: action((state, value) => {
    state.selectedDate = value;
  }),
  setSelectedDates: action((state, value) => {
    state.selectedDates = value;
  }),
  setTableData: action((state, value) => {
    state.tableData = value;
  }),
  resetTableData: action((state) => {
    state.tableData = defaultTableData;
  }),
  handleInclude: action((state, value) => {
    const oldData = [...state.simplifiedProjects];
    const newProjectIds = [...value];
    const newData = oldData.map((project) => {
      const existingProjectId = newProjectIds.find(
        (id) => id === project.project_id,
      );
      if (existingProjectId) {
        return {
          ...project,
          include: !project.include,
        };
      }
      return project;
    });
    state.simplifiedProjects = newData;
  }),
  handleIncludeAll: action((state, keys) => {
    const data = state.simplifiedProjects.filter((p) =>
      keys.includes(p.project_id),
    );
    const includedKeys = data.filter((p) => p.include);
    const newData = [...state.simplifiedProjects];
    if (includedKeys.length === data.length) {
      state.simplifiedProjects = newData.map((p) => ({ ...p, include: false }));
    } else {
      state.simplifiedProjects = newData.map((p) => ({ ...p, include: true }));
    }
  }),
  fetchAllProjects: thunk(async (actions, _payload, helpers) => {
    const {
      market: { setProjectsLoading },
    } = helpers.getStoreActions();
    const {
      market: { mode, simplifiedProjects },
      filters: { overviewData: { cities } = {}, unitsType },
    } = helpers.getStoreState();
    if (cities) {
      try {
        setProjectsLoading(true);
        const fetchPromises = cities?.map((city) => fetchMarketProjects([city]));
        const results = await Promise.all(fetchPromises);
        const dashboardData = results.map((response) => response.data).flat();

        const isCreating = mode === Mode.CREATE;
        const mappedData = dashboardData.map((item) => ({
          ...item,
          include: isCreating
            ? false
            : simplifiedProjects.find((p) => p.project_id === item.project_id)
                ?.include,
          sold_avg_price: item.unavailable_units?.avg_price,
          sold_avg_price_per_sm: item.unavailable_units?.avg_price_per_sm,
        }));

        const newSimplifiedProjects = mappedData
          .map((project) => ({
            gps_latitude: project.gps_latitude,
            gps_longitude: project.gps_longitude,
            include: project.include,
            project_id: project.project_id,
            available_units: project.available_units,
            reserved_units: 0,
            sold_units: project.sold_units,
            avg_price: project.avg_price,
            avg_price_per_sm: project.avg_price_per_sm || null,
            sold_avg_price: project.sold_avg_price || null,
            sold_avg_price_per_sm: project.sold_avg_price_per_sm,
            total_number_of_units: project.total_number_of_units,
            project_name: project.project_name,
            developer: project.developer,
            amenities: project.amenities,
            schools: project.schools,
            district: project.district,
            city: project.city,
            municipality: project.municipality,
            date_construction_completion: project.date_construction_completion,
            layouts: project.layouts,
            baths: project.baths,
            garages: project.garages,
            unit_type: project.unit_type,
          }))
          .filter((project) => unitsType.includes(project.unit_type));
        actions.setSimplifiedProjects(newSimplifiedProjects);
        const newData = {
          ...defaultTableData,
          filteredData: [...newSimplifiedProjects],
        };
        actions.setTableData(newData);
      } catch (error) {
        logError(error);
      } finally {
        setProjectsLoading(false);
      }
    }
  }),
  createPrimaryDashboard: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      filters: { fetchOverviewData },
    } = helpers.getStoreActions();
    const { primaryParameters, simplifiedProjects } = helpers.getState();
    setGlobalLoading(true);
    try {
      if (primaryParameters) {
        await createMarketDashboard({
          ...primaryParameters,
          included_project_ids: simplifiedProjects
            .filter((p) => !!p.include)
            .map((p) => p.project_id),
          remaining_project_ids: simplifiedProjects
            .filter((v) => !!!v.include)
            .map((v) => v.project_id),
        });
      }

      await fetchOverviewData();
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  createSecondaryDashboard: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      secondaryMarket: { fetchReports },
    } = helpers.getStoreActions();
    const { secondaryParameters, polygons } = helpers.getState();
    setGlobalLoading(true);
    try {
      if (secondaryParameters) {
        const secondaryTypes = secondaryParameters.market_types.filter(
          (market_type) => market_type !== MarketTypeEnum.PRIMARY,
        );
        await Promise.all(
          secondaryTypes.map(async (market_type) =>
            createSecondaryReport({
              country: secondaryParameters.country,
              geometry: getGeometry(
                Object.values(polygons).map((p) => [
                  ...p.map((point) => new window.google.maps.LatLng(point)),
                ]),
              ),
              building_conditions: secondaryParameters.building_conditions,
              name: secondaryParameters.name,
              user_id: secondaryParameters.user_id,
              market_type,
            }),
          ),
        );
        await fetchReports();
      }
    } catch (error) {
      console.error(error);
      // logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  fetchDashboardSettings: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      market: { setPolygons },
    } = helpers.getStoreActions();
    const {
      filters: { paramsSM },
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    const { price_per_sm_calculation, VAT_included, reserved_as_sold } = profile || {};
    try {
      if (paramsSM?.reportId) {
        const secondaryMultiPolygon = await getSecondaryMultiPolygon(
          paramsSM.reportId,
        );
        const parsedPolygons = parseGeometry(secondaryMultiPolygon);
        setPolygons(JSON.parse(JSON.stringify(parsedPolygons)));
      }
      if (payload) {
        const { dashboardId, date } = payload;
        const { data } = await fetchMarketDashboardSimplified(
          dashboardId,
          date,
          price_per_sm_calculation as PricePerSmCalcType,
          VAT_included,
          reserved_as_sold,
        );
        actions.setSimplifiedProjects(data.projects);
        const newData = { ...defaultTableData, filteredData: data.projects };
        actions.setTableData(newData);
      } else {
        actions.setSimplifiedProjects([]);
        actions.setTableData(defaultTableData);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  fetchDashboardOverview: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      market: { setPolygons },
    } = helpers.getStoreActions();
    const {
      market: { polygons },
      filters: { paramsPM, paramsSM },
      user: { profile: { price_per_sm_calculation } = {} },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      if (paramsSM?.reportId) {
        const secondaryMultiPolygon = await getSecondaryMultiPolygon(
          paramsSM.reportId,
        );
        const parsedPolygons = parseGeometry(secondaryMultiPolygon);
        setPolygons(JSON.parse(JSON.stringify(parsedPolygons)));
      }
      if (
        (!polygons || Object.values(polygons).length === 0) &&
        payload?.dashboardId &&
        price_per_sm_calculation &&
        paramsPM?.date
      ) {
        // const dejtej = await fetchBlockMapData(
        //   payload.dashboardId,
        //   paramsPM.date,
        //   price_per_sm_calculation || PricePerSMCalcEnum.WITHOUT_EXTERIOR,
        // );
      }
      if (payload?.dashboardId) {
        const { data } = await fetchMarketDashboardOverview(
          payload.dashboardId,
        );
        actions.setDashboardOverviewData(data);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  deleteDashboard: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      filters: { fetchOverviewData },
      secondaryMarket: { fetchReports },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      if (payload.primaryId) {
        await deleteMarketDashboard(payload.primaryId);
      }
      if (payload.secondaryId) {
        await reportsApi.reportsEndpointsDeleteReport({
          reportId: payload.secondaryId,
        });
      }
      if (payload.rentId) {
        await reportsApi.reportsEndpointsDeleteReport({
          reportId: payload.rentId,
        });
      }
      if (payload.rentId || payload.secondaryId) {
        await fetchReports();
      }
      await fetchOverviewData();
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  updateDashboard: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
      filters: { fetchOverviewData },
      secondaryMarket: { fetchReports },
    } = helpers.getStoreActions();
    const {
      simplifiedProjects,
      primaryParameters,
      secondaryParameters,
      polygons,
    } = helpers.getState();
    const {
      filters: { paramsPM: marketParams, paramsSM },
      secondaryMarket: { reports },
    } = helpers.getStoreState();
    const dashboardId = marketParams?.dashboardId;

    setGlobalLoading(true);

    try {
      if (dashboardId) {
        if (primaryParameters) {
          await updateMarketDashboard({
            included_project_ids: simplifiedProjects
              .filter((p) => !!p.include)
              .map((p) => p.project_id),
            remaining_project_ids: simplifiedProjects
              .filter((v) => !!!v.include)
              .map((v) => v.project_id),
            dashboard_id: dashboardId,
            dashboard_name: primaryParameters.dashboard_name,
            geometry:
              getGeometry(
                Object.values(polygons).map((p) => [
                  ...p.map((point) => new window.google.maps.LatLng(point)),
                ]),
              ) ?? null,
            include_new_projects:
              primaryParameters?.include_new_projects ?? false,
          });
        } else {
          // Delete the primary dashboard if the user has removed the primary option
          await deleteMarketDashboard(dashboardId);
        }
      }
      if (paramsSM && reports) {
        const allReports = reports.filter((r) => r.name === paramsSM.name);
        if (secondaryParameters) {
          // if (!paramsSM.polygonsEdited) {
          //   await Promise.all([
          //     ...allReports.map(async (r) => reportsApi.reportsEndpointsUpdateReportName({
          //       name: secondaryParameters.name,
          //       reportId: r.reportId,
          //     })),
          //   ]);
          // } else {
          await Promise.allSettled(
            allReports.map(async (r) => {
              // Delete the dashboard first
              try {
                // console.log('[marketStore] Deleting secondary report');
                await reportsApi.reportsEndpointsDeleteReport({
                  reportId: r.reportId,
                });
              } catch (error) {
                // console.error(`[marketStore] updateDashboard - Failed to delete report ${r.reportId} `, error);
              }

              // Create a new dashboard
              try {
                // console.log('[marketStore] Creating secondary report');
                await createSecondaryReport({
                  country: secondaryParameters.country,
                  geometry: getGeometry(
                    Object.values(polygons).map((p) => [
                      ...p.map((point) => new window.google.maps.LatLng(point)),
                    ]),
                  ),
                  building_conditions: secondaryParameters.building_conditions,
                  name: secondaryParameters.name,
                  user_id: secondaryParameters.user_id,
                  market_type: r.reportType as MarketTypeEnum,
                });
              } catch (error) {
                // console.error(`[marketStore] updateDashboard - Failed to create report ${r.reportId} `, error);
              }
            }),
          );
          // }
        } else {
          // Delete the secondary dashboard(s) if the user has removed the secondary option
          await Promise.allSettled(
            allReports.map(async (r) => {
              // Delete the dashboard first
              try {
                await reportsApi.reportsEndpointsDeleteReport({
                  reportId: r.reportId,
                });
                // console.log('[marketStore] updateDashboard - Deleted report ', r.reportId);
              } catch (error) {
                // console.error(`[marketStore] updateDashboard - Failed to delete report ${r.reportId} `, error);
              }
            }),
          );
        }
        // Create a secondary dashboard from scratch
      } else if (!paramsSM && secondaryParameters) {
        await Promise.allSettled(
          (secondaryParameters.market_types ?? []).map(async (market) => {
            await createSecondaryReport({
              country: secondaryParameters.country,
              geometry: getGeometry(
                Object.values(polygons).map((p) => [
                  ...p.map((point) => new window.google.maps.LatLng(point)),
                ]),
              ),
              building_conditions: secondaryParameters.building_conditions,
              name: secondaryParameters.name,
              user_id: secondaryParameters.user_id,
              market_type: market,
            });
          }),
        );
      }
      await fetchReports();
      fetchOverviewData();
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  fetchUserTemplates: thunk(async (actions) => {
    try {
      const { data } = await fetchUserMDTemplates();
      actions.setTemplates(data);
    } catch (error) {
      logError(error);
    }
  }),
};
