/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { Row, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProjectDetailTable } from '../ProjectDetailTable';
import { aggregatePriceListData, getSumFromNotNullValues } from '../../../../../utils/utils';
import { Description } from './Description';
import { styles } from '../styles';
import { Data } from '../types';
import { Availability } from '../../../../../store/types';
import { useReservedData } from '../../../../../hooks/useReservedData';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../../../../hooks';
import { FilterEnum } from '../../../../project/useFilter';
import { PricePerSmCalcType } from '../../../../TopBar/SettingsPopover';
import { PricePerSMCalcEnum } from '../../../../../api/enums';

interface Props {
  data: Data;
}

interface Summary {
  key: 'available' | 'interior_sm' | 'price_per_sm' | 'price',
  value: number | string | null,
}

export const Content: FC<Props> = ({ data }) => {
  const { price_list: priceList } = data;
  const setAvailable = useStoreActions(
    (state) => state.filters.setAvailability,
  );
  const available = useStoreState((state) => state.filters.availability);
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();
  const { sortByLayouts, formatCurrency } = useRegionFormatting();

  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  const handleChange = (state: string | number): void => {
    setAvailable(String(state) as FilterEnum);
  };

  const { tableData, priceListData, customSummary } = useMemo(() => {
    const mappedPriceList = mapReservedData(priceList);
    const aggregatedTableData = aggregatePriceListData(mappedPriceList);

    const filteredPriceListdata = mappedPriceList.filter(
      ({ availability }) =>
        availability ===
        (available === FilterEnum.AVAILABLE
          ? Availability.AVAILABLE
          : Availability.SOLD),
    );

    const filteredPriceListdataWithPrices = filteredPriceListdata.filter(
      ({ price }) => price !== null,
    );
    const totalPrice = getSumFromNotNullValues(filteredPriceListdataWithPrices.map((obj) => obj.price)) || 0;
    const totalEquivalentArea = getSumFromNotNullValues(filteredPriceListdataWithPrices.map((obj) => obj.equivalent_area)) || 0;
    const pricePerSm = totalEquivalentArea !== 0 ? totalPrice / totalEquivalentArea : 0;
    const summary: Summary[] = [{ key: 'price_per_sm', value: formatCurrency(pricePerSm) }];

    return {
      tableData: sortByLayouts(
        aggregatedTableData.filter(
          ({ availability }) =>
            availability ===
            (available === FilterEnum.AVAILABLE
              ? Availability.AVAILABLE
              : Availability.SOLD),
        ),
      ),
      priceListData: filteredPriceListdata,
      customSummary: pricePerSm !== 0 ? summary : undefined,
    };
  }, [available, mapReservedData, priceList, sortByLayouts, formatCurrency]);

  return (
    <>
      <Description data={data} />
      <Row css={styles.segmentedContainer}>
        <Segmented
          value={available}
          options={[
            {
              value: FilterEnum.AVAILABLE,
              label: t('enums.state.available', 'Available'),
            },
            {
              value: FilterEnum.SOLD,
              label: t('enums.state.sold', 'Sold'),
            },
          ]}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <ProjectDetailTable
          dataSource={tableData}
          priceList={priceListData}
          floorCoefficient={data.floor_coefficient}
          customSummary={(pricePerSmCalculation === PricePerSMCalcEnum.WITH_EXTERIOR && !data.using_total_area) ? customSummary : undefined}
          using_total_area={data.using_total_area}
        />
      </Row>
    </>
  );
};
