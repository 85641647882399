import { ArrowsPointingOutIcon, MapIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import styles from './MapControls.module.css';

type Props = {
  mapParam: google.maps.Map
  defaultZoom?: number
}

export const MapControls: FC<Props> = ({ mapParam, defaultZoom = 11 }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mapType, setMapType] = useState<'roadmap' | 'satellite'>('roadmap');
  const center = mapParam.getBounds()?.getCenter() ?? mapParam.getCenter() ?? null;

  const handleFullscreenToggle = (): void => {
    const mapElement = mapParam.getDiv();
    if (!isFullscreen) {
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const handleFullscreenChange = (): void => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleMapTypeChange = (type: 'roadmap' | 'satellite'): void => {
    setMapType(type);
    mapParam.setMapTypeId(type);
  };

  const handleZoom = (type: 'in' | 'out'): void => {
    const currentZoom = mapParam.getZoom() || defaultZoom;
    mapParam.setZoom(type === 'in' ? currentZoom + 1 : currentZoom - 1);
  };

  const handleCenterPosition = (): void => {
    if (center) {
        mapParam.panTo(center);
    } else {
      message.error('Could not get center position');
    }
  };

  return (
    <>
      <Button.Group className={styles.mapTypeBtnGrp}>
        <Button
          className={styles.mapBtn}
          onClick={() => handleMapTypeChange('roadmap')}
          style={{ fontWeight: mapType === 'roadmap' ? 700 : 400 }}
        >
          Map
        </Button>
        <div className='bg-bmlightgray w-1' />
        <Button
          className={styles.mapBtn}
          onClick={() => handleMapTypeChange('satellite')}
          style={{ fontWeight: mapType === 'satellite' ? 700 : 400 }}
        >
          Satellite
        </Button>
      </Button.Group>
      <Button type='ghost' className={styles.fullScreenBtn} onClick={handleFullscreenToggle}>
        <ArrowsPointingOutIcon className={styles.icon} />
      </Button>
      <div className={styles.bottomRightContainer}>
        <Button type='ghost' className={styles.centerBtn} onClick={handleCenterPosition}>
          <MapIcon className={styles.icon} />
        </Button>
        <Button.Group className={styles.zoomBtnGrp}>
          <Button
            className={styles.zoomBtn}
            onClick={() => handleZoom('out')}
          >
            <MinusIcon className={styles.icon} />
          </Button>
          <div className='bg-bmlightgray w-1' />
          <Button
            className={styles.zoomBtn}
            onClick={() => handleZoom('in')}
          >
            <PlusIcon className={styles.icon} />
          </Button>
        </Button.Group>
      </div>
    </>
  );
};
