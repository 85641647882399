import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Layout as LayoutData } from '../../../api/mockData/priceListDetailData';
import { formatNumber } from '../../../utils/formatters';
import { PriceBreakdownRow } from './PriceBreakdownRow';

interface Props {
  data: LayoutData;
  isHouse: boolean;
}
export const Layout: FC<Props> = ({
  data: {
    category,
    kitchen_luminosity,
    next_to_elevator,
    ratio_living,
    noise,
    num_of_bathrooms,
    permit_regular,
    ratio_bedrooms,
    walk_through_room,
    house_type,
    // masters_bedroom,
  },
  isHouse,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <PriceBreakdownRow
        className='row-top-spacing'
        houseAreaKey='layout'
        housePartKey='num_of_bathrooms'
        valueContent={num_of_bathrooms.value}
        price={num_of_bathrooms.price}
        updatePrice={num_of_bathrooms.update_price}
      />
      <PriceBreakdownRow
        housePartKey='walk_through_room'
        valueContent={walk_through_room.value}
        price={walk_through_room.price}
        updatePrice={walk_through_room.update_price}
      />
      <PriceBreakdownRow
        housePartKey='kitchen_luminosity'
        valueContent={kitchen_luminosity.value}
        price={kitchen_luminosity.price}
        updatePrice={kitchen_luminosity.update_price}
      />
      {!isHouse ? (
        <PriceBreakdownRow
          housePartKey='next_to_elevator'
          valueContent={next_to_elevator.value}
          price={next_to_elevator.price}
          updatePrice={next_to_elevator.update_price}
        />
      ) : null}
      <PriceBreakdownRow
        housePartKey='ratio_bedrooms'
        valueContent={formatNumber(ratio_bedrooms.value, { round: 2 })}
        price={ratio_bedrooms.price}
        updatePrice={ratio_bedrooms.update_price}
      />
      {ratio_living && ratio_living.value > 0 && (
        <PriceBreakdownRow
          housePartKey='ratio_living'
          valueContent={formatNumber(ratio_living.value, { round: 2 })}
          price={ratio_living.price}
          updatePrice={ratio_living.update_price}
        />
      )}
      <PriceBreakdownRow
        housePartKey='noise'
        valueContent={noise.value}
        popoverContent={
          <Trans i18nKey='price_list.hints.noise.desc'>
            3 if there is nature or no building in the next 100m. <br />
            2 if the next building is 15- 100m away. <br />1 if the next
            building is less than 15m away from the unit from the living room.
          </Trans>
        }
        price={noise.price}
        updatePrice={noise.update_price}
      />
      {!isHouse ? (
        <>
          <PriceBreakdownRow
            housePartKey='permit_regular'
            valueContent={permit_regular.value}
            price={permit_regular.price}
            updatePrice={permit_regular.update_price}
          />
          <PriceBreakdownRow
            housePartKey='category'
            valueContent={t(`enums.category.${category.value}`, category.value)}
            price={category.price}
            updatePrice={category.update_price}
            className='row-spacing'
          />
        </>
      ) : (
        <>
          <PriceBreakdownRow
            housePartKey='house_type'
            valueContent={t(
              `enums.house_type.${house_type.value}`,
              house_type.value,
            )}
            price={house_type.price}
            updatePrice={house_type.update_price}
            className='row-spacing'
          />
        </>
      )}
    </div>
  );
};
