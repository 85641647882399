import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  options: Array<{ id: string | number; name: string }>;
  value: string | number | undefined;
  onChange: (value: any) => void;
  optionFilterProp: string;
  bordered?: boolean;
  placeholder?: string;
  className?: string;
  rootClassName?: string;
}

export const SelectSearch: FC<Props> = ({ options, value, onChange, optionFilterProp, bordered, placeholder, className, rootClassName }) => {
  const { Option } = Select;

  return (
    <Select
      className={className}
      rootClassName={rootClassName}
      showSearch
      bordered={bordered}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      dropdownMatchSelectWidth
      optionFilterProp={optionFilterProp}
      filterOption={(input, option) =>
      // @ts-expect-error
      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
    >
      {options.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))}
    </Select>
    );
  };
