import { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddedUnits, DailyNewsData, Discount, GraphData, NewProjects, NewSoldOutProjects } from '../../../store/dailyNews';

export type KPIItem = 'added_units' | 'new_sold_out_projects' | 'new_projects' | 'graph_data' | 'discounts'

export type KPIDataType = {
  added_units: AddedUnits | null;
  new_sold_out_projects: NewSoldOutProjects | null;
  new_projects: NewProjects | null;
  graph_data: GraphData | null;
  discounts: Discount[] | null;
};

type KPIFilterButtonProps = {
  icon: string;
  backgroundColor: string;
  item: KPIItem;
};

type KPIFilterProps = {
  data?: Partial<DailyNewsData>
  KPIData: KPIDataType;
  setKPIData: (data: KPIDataType) => void;
};

export const KPIFilter: FC<KPIFilterProps> = ({ data, KPIData, setKPIData }) => {
  const { t } = useTranslation();
  const KPIFilterButton: FC<KPIFilterButtonProps> = ({ icon, backgroundColor, item }) => {
    const handleFilter = (): void => {
      if (KPIData[item]) {
        setKPIData({
          ...KPIData,
          [item]: null,
        });
      } else {
        setKPIData({
          ...KPIData,
          [item]: data![item],
        });
      }
    };
    return (
      <Tooltip title={t(`daily_news.filters.${item}`)}>
      <Button
        type='ghost'
        className={`rounded-full p-2 bg-[${backgroundColor}]`}
        style={{ opacity: KPIData[item] ? 1 : 0.5 }}
        onClick={() => handleFilter()}
      >
        <img src={`assets/${icon}`} alt={icon} className='h-7 w-7' />
      </Button>
      </Tooltip>
    );
  };

  return (
    <div className='flex space-x-3 items-center'>
      <KPIFilterButton icon='price-tag-dark-blue.png' backgroundColor='#9EB9DA' item='new_projects' />
      <KPIFilterButton icon='trophy-dark-blue.png' backgroundColor='#9EB9DA' item='graph_data' />
      {/* <KPIFilterButton icon='calendar-dark-blue.png' backgroundColor='#9EB9DA' /> */}
      <KPIFilterButton icon='home-dark-blue.png' backgroundColor='#9EB9DA' item='added_units' />
      <KPIFilterButton icon='price-tag-grey.png' backgroundColor='#BDBDBD' item='discounts' />
      <KPIFilterButton icon='wallet-dark-grey.png' backgroundColor='#BDBDBD' item='new_sold_out_projects' />
    </div>
  );
};
