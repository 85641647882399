import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../api/mockData/projectData';
import { Card } from '../Card';
import { Content } from '../map/ProjectDetail/Content';
import { Export } from './Export';

interface Props {
  data: Project;
}

export const Overview: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('project.overview.title', 'Project Overview')}
      extra={<Export />}
      subtitle={
      (data?.discounts && !!data?.discounts[0]?.discount_text) &&
        <div className='daily_news_list_item_container_value' style={{ margin: '.5em 0' }}>
          <div className='daily_news_list_item_value border'>
            <div className='daily_news_list_item_trans'>
              <div className='daily_news_icon'>
                <img style={{ width: '18px', height: '18px' }} src='../assets/price-tag-dark-blue.png' alt='price_tag' />
              </div>
              <div style={{ paddingRight: '1em' }}>
                <b>{data.discounts[0].discount_text}</b>
              </div>
            </div>
          </div>
        </div>
        }
    >
      <Content data={data} />
    </Card>
  );
};
