import { FC, useState } from 'react';
import { ArrowsPointingOutIcon, ChevronLeftIcon, ChevronRightIcon, FlagIcon } from '@heroicons/react/24/outline';
import { PieChart } from 'react-minimal-pie-chart';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../Card';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { Target, Targets as TargetsData } from '../../../../api/mockData/sales';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import styles from './Targets.module.css';

type TargetColumnProps = {
  target: Target;
}

type TargetProps = {
  onMaximize: () => void;
  targets: TargetsData;
}

const TargetColumn: FC<TargetColumnProps> = ({ target }) => {
  const { t } = useTranslation();
  const language = useStoreState((state) => state.user.profile?.language);
  const { getCurrency } = useRegionFormatting();
  const currency = getCurrency(true);
  const targetTypeValues: { [key: string]: string } = {
    floor_area: language === 'en' ? 'SM' : 'm2',
    revenue: currency,
  };

  const calculateRatio = (item: number): string | number => {
    const bigNumberFormatter = (num: number): string => {
      if (num > 1e6) {
        return `${(num / 1e6).toFixed(2)}m`;
      } if (num < 1e6 && num > 1e4) {
        return `${(num / 1e3).toFixed(2)}K`;
      }
        return `${num}`;
    };
    switch (target.target_type) {
      case 'revenue':
        return bigNumberFormatter(item);
      case 'floor_area':
        return bigNumberFormatter(item);
      default:
        return Math.round(item);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t(`sales.targets.subtitle.${target.target_type}`)}</div>
      <div className={styles.wrapper}>
      <PieChart
        lineWidth={60}
        startAngle={-90}
        animate
        className={styles.pieChart}
        data={[
          { value: Math.round(target.progress || 0), color: AVAILABLE },
          { value: 100 - Math.round(target.progress || 0), color: SOLD },
        ]}
      />
      <span className={styles.fontBold}>{Math.round(target.progress || 0)}%</span>
      </div>
      {/* <div className='flex text-base space-x-2'>
        <span>
          10/{calculateRatio(target.target_value)}
        </span>
        {target.target_type !== 'units' && (
          <span>
            {targetTypeValues[target.target_type]}
          </span>
        )}
      </div> */}
    </div>
  );
};

export const Targets: FC<TargetProps> = ({ onMaximize, targets }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const sortedTargets = targets.targets.sort((a, b) => {
    if (a.mandatory === b.mandatory) {
      return dayjs(a.target_month).isBefore(dayjs(b.target_month)) ? -1 : 1;
    }
    return a.mandatory ? -1 : 1;
  });
  const paginateTargets = (targetsArray?: Target[]): Record<number, Target[]> => {
    if (targetsArray && targetsArray.length > 0) {
      const results = targetsArray.reduce((pagesCount: Record<number, Target[]>, item, index) => {
        const pageNumber = Math.floor(index / 3) + 1;
        const pageNo = pagesCount[pageNumber] || [];
        pageNo.push(item);
        return { ...pagesCount, [pageNumber]: pageNo };
      }, {});
      if (results[Object.keys(results).length].length === 3) {
        results[Object.keys(results).length + 1] = [];
      }
      return results;
    }
    return [];
  };
  const pagesResults = paginateTargets(sortedTargets);
  const pages = Object.keys(pagesResults);
  const emptyDivsCount = Math.max(0, 3 - (pagesResults[page] ? pagesResults[page].length : 0));

  const handlePaginate = (direction: 'next' | 'prev'): void => {
    if (direction === 'next') {
      setPage(page < pages.length ? page + 1 : page);
    } else {
      setPage(page > 1 ? page - 1 : page);
    }
  };

  return (
    <Card bordered={false} className='targets'>
      <div className={styles.targets}>
        <div className={styles.targetsHeaderContainer}>
          <div className={styles.targetsHeader}>
            <div className={styles.badge}>
              <FlagIcon className={styles.icon} />
            </div>
          <div>{t('sales.targets.title', 'Sales Targets')}</div>
          </div>
          <ArrowsPointingOutIcon className={styles.maximize} onClick={onMaximize} />
        </div>
        {page > 1 && <ChevronLeftIcon className={styles.chevronLeft} onClick={() => handlePaginate('prev')} />}
        <div className={styles.columns}>
          {pagesResults[page] && pagesResults[page].map((target) => (
            <TargetColumn target={target} />
          ))}
          {Array.from({ length: emptyDivsCount }).map(() => (
            <span className={styles.addNew} onClick={onMaximize}>+ {t('sales.targets.add_new', 'Add New')}</span>
          ))}
        </div>
        { pages && page < pages.length && <ChevronRightIcon className={styles.chevronRight} onClick={() => handlePaginate('next')} />}
      </div>
    </Card>
  );
};
