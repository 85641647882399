/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../../routes/Paths';
import { MaximizeIcon } from '../../../MaximizeIcon';
import { styles } from './styles';
import { MarketProjectExtended } from '../../../../store/types';

interface Props {
  data: Pick<
    MarketProjectExtended,
    'project_id' | 'project_name' | 'project_url' | 'address' | 'developer' | 'news' | 'discounts'
  >;
  onClose: () => void;
}

export const Header: FC<Props> = ({
  data: { address, developer, project_id, project_name, project_url, news, discounts },
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div css={styles.container}>
      <div css={styles.titleContainer}>
        <div css={styles.title}>
          <Link
            to={generatePath(Paths.PROJECT_DETAIL, {
              projectId: project_id,
            })}
          >
            {project_name}
          </Link>
        </div>
        <div className='project-link-container'>
          {project_url && (
            <Popover
              content={t(
                'project.links.project_website',
                'Open the project website',
              )}
            >
              <div className='project-link'>
                <a href={project_url} target='_blank' rel='noopener noreferrer'>
                  <LinkOutlined />
                </a>
              </div>
            </Popover>
          )}
          <MaximizeIcon
            content={t(
              'project.links.project_detail',
              'Open detail of the project',
            )}
            to={generatePath(Paths.PROJECT_DETAIL, {
              projectId: project_id,
            })}
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className='times' onClick={() => onClose()} style={{ lineHeight: '1em' }}>
            <span>
            &times;
            </span>
          </div>
        </div>
      </div>
      { news &&
        <div className='daily_news_list_item_container_value' style={{ margin: '.5em 0' }}>
          <div className='daily_news_list_item_value border'>
            <div className='daily_news_list_item_trans'>
              <div className='daily_news_icon'>
                <img style={{ width: '18px', height: '18px' }} src='assets/price-tag-dark-blue.png' alt='price_tag' />
              </div>
              <div style={{ paddingRight: '1em' }}>
                <b>{news}</b>
              </div>
            </div>
          </div>
        </div>}
        {
        (discounts && !!discounts[0]?.discount_text) &&
        <div className='daily_news_list_item_container_value' style={{ margin: '.5em 0' }}>
          <div className='daily_news_list_item_value border'>
            <div className='daily_news_list_item_trans'>
              <div className='daily_news_icon'>
                <img style={{ width: '18px', height: '18px' }} src='../assets/price-tag-dark-blue.png' alt='price_tag' />
              </div>
              <div style={{ paddingRight: '1em' }}>
                <b>{discounts[0].discount_text}</b>
              </div>
            </div>
          </div>
        </div>
        }
      <div>{address}</div>
      <div>{developer}</div>
    </div>
  );
};
